import api from '@utils/api';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import qs from 'query-string';
import {
  DEFAULT_SORT_OPTION,
  SORT_OPTIONS,
} from '@services/useInsightsPosts';

const useInsightsFilters = ({
  topic = null,
  persona = null,
}) => {
  const parsedQueryFilters = typeof window !== 'undefined' && qs.parse(window.location.search);
  const initialOrder = SORT_OPTIONS.includes(parsedQueryFilters?.ordering) ?
    parsedQueryFilters?.ordering :
    DEFAULT_SORT_OPTION;

  const initialFilters = {
    industry: parsedQueryFilters?.industry,
    ordering: initialOrder,
    persona,
    technology: parsedQueryFilters?.technology,
    theme: parsedQueryFilters?.theme,
    topic,
    type: parsedQueryFilters?.type,
  };

  const [
    filters,
    setFilters,
  ] = useState(initialFilters);

  const [
    availableFilterOptions,
    setAvailableFilters,
  ] = useState(null);

  const changeFilter = (filterName, option) => {
    setFilters((state) => ({
      ...state,
      [filterName]: option?.value,
    }));
  };

  const clearFilter = (filterName) => {
    setFilters((state) => ({
      ...state,
      [filterName]: undefined,
    }));
  };

  const handleUpdateUrl = useMemo(() => (newFilters) => {
    const filtersQueryString = qs.stringify({
      ...newFilters,
      ordering: newFilters.ordering === DEFAULT_SORT_OPTION ? '' : newFilters.ordering,
      persona: null,
      topic: null,
    }, {
      skipEmptyString: true,
      skipNull: true,
    });

    const newQuerySearch = filtersQueryString ? `?${filtersQueryString}` : '';
    const searchParams = typeof window !== 'undefined' ? window.location.search : '';

    if (newQuerySearch !== searchParams) {
      const newUrl = newQuerySearch || `${window.location.origin}${window.location.pathname}`;

      window.history.replaceState(newFilters, '', newUrl);
    }
  }, []);

  useEffect(() => {
    handleUpdateUrl(filters);

    const fetchAvailableFilters = async () => {
      try {
        const baseFilters = {
          industry: filters.industry,
          persona,
          technology: filters.technology,
          theme: filters.theme,
          topic,
          type: filters.type,
        };

        const availableThemesPromise = api.get('/content-insights/filter-options', {
          params: {
            ...baseFilters,
            theme: null,
          },
        });

        const availableTechnologyPromise = api.get('/content-insights/filter-options', {
          params: {
            ...baseFilters,
            technology: null,
          },
        });
        const availableIndustryPromise = api.get('/content-insights/filter-options', {
          params: {
            ...baseFilters,
            industry: null,
          },
        });
        const availableTypePromise = api.get('/content-insights/filter-options', {
          params: {
            ...baseFilters,
            type: null,
          },
        });

        const [
          { value: availableThemesOptions },
          { value: availableTechnologyOptions },
          { value: availableIndustryOptions },
          { value: availableTypeOptions },
        ] = await Promise.allSettled([
          availableThemesPromise,
          availableTechnologyPromise,
          availableIndustryPromise,
          availableTypePromise,
        ]);

        const parsedData = {
          content_types: availableTypeOptions?.data.content_types.map((i) => i.content_type),
          industries: availableIndustryOptions?.data.industries.map((i) => i.name),
          technologies: availableTechnologyOptions?.data.technologies.map((i) => i.name),
          themes: availableThemesOptions?.data.themes.map((i) => i.name),
        };

        setAvailableFilters(parsedData);
      } catch (error) {
        setAvailableFilters(null);
      }
    };

    fetchAvailableFilters();
  }, [filters]);

  return {
    availableFilterOptions,
    changeFilter,
    clearFilter,
    filters,
    setFilters,
  };
};

export default useInsightsFilters;
