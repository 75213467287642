import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import useResizeObserver from 'use-resize-observer/polyfilled';
import useInsightsFilters from '@services/useInsightsFilters';
import mediaQueries, { breakpoints } from '@utils/media-queries';
import InsightsGlobalStyles from '@components/Insights/InsightsGlobalStyles.styled';
import InsightsHead from '@components/Insights/InsightsHead/InsightsHead';
import {
  CommonScripts,
  GridStairs,
  GridTransparent,
  HomeHeader,
  HomepageFilters,
  InsightsPosts,
  Reviews,
} from '@components';
import {
  headerStairsMobile,
  homeCollectionsStairs,
  homeHeaderStairs,
  homeHeaderStairsMedium,
  homeHeaderStairsMobile,
  insightsGridConfig,
} from '@utils/grid';
import {
  getOptionsByAttr,
  sortByLabel,
} from '@utils/helpers';
import { colors } from '@utils/vars';
import BottomBar from '@components/BottomBar/BottomBar';

const {
  small, large, xlarge,
} = mediaQueries;

const {
  markerYellow: markerYellowColor,
} = colors;

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font-size: 14px;

  @media ${xlarge} {
    font-size: 13px;
  }

  @media ${large} {
    font-size: 12px;
  }

  @media ${small} {
    overflow: scroll;
  }

  small {
    font-size: smaller;
  }

  .marker {
    background: ${markerYellowColor};
  }
`;

const ContentContainer = styled.div`
  position: relative;
  padding-top: 70px;
  padding-bottom: 200px;

  @media ${xlarge} {
    padding-bottom: 100px;
  }
`;

const Landing = ({
  location,
  pageContext: {
    content: contentProp = {},
    mock = {},
  },
}) => {
  const content = {
    ...mock,
    ...contentProp,
  };
  const {
    ref, width: windowWidth = 0,
  } = useResizeObserver();

  const {
    header_image: headerImage,
    footer_image: footerImage,
    headline,
    title,
    description: subtitle,
    seo: meta,
    technologies,
    industries,
    content_types,
    subtopics,
    clutch,
    menu_items: menuItems,
    social_media_icons: socialItems,
    reviews,
  } = content;

  const parsedClutch = {
    ...clutch,
    firstText: clutch.first_text,
    reviewsUrl: clutch.reviews_url,
    secondText: clutch.second_text,
  };

  let headerStairs = homeHeaderStairsMobile;

  if (windowWidth > breakpoints.small) {
    headerStairs = homeHeaderStairsMedium;
  }

  if (windowWidth > breakpoints.medium) {
    headerStairs = homeHeaderStairs;
  }

  const themesOptions = getOptionsByAttr(subtopics, 'name').sort(sortByLabel);
  const typesOptions = getOptionsByAttr(content_types, 'content_type').sort(sortByLabel);
  const industryOptions = getOptionsByAttr(industries, 'name').sort(sortByLabel);
  const technologyOptions = getOptionsByAttr(technologies, 'name').sort(sortByLabel);

  const {
    filters, changeFilter, clearFilter, availableFilterOptions,
  } = useInsightsFilters({
    persona: content.persona?.name,
    topic: content.topic?.name,
  });

  const pagePath = typeof window !== 'undefined' ?
    `${window.location.pathname}${window.location.search}` :
    `${location.pathname}${location.search}`;

  return (
    <Wrapper ref={ref}>
      {meta && <InsightsHead meta={meta} />}
      <CommonScripts />
      <InsightsGlobalStyles />
      <ThemeProvider theme={{ awesomegrid: insightsGridConfig }}>
        <HomeHeader
          image={headerImage}
          title={headline}
          subtitle={subtitle}
          menuItems={menuItems}
          socialItems={socialItems}
          navbarSubtitle={title}
        />
        <GridStairs offsets={headerStairs} />
        <ContentContainer>
          <GridTransparent zIndex={0} />
          <HomepageFilters
            technologies={technologyOptions}
            industries={industryOptions}
            contentTypes={typesOptions}
            themes={themesOptions}
            selectedTheme={filters.theme}
            selectedTechnology={filters.technology}
            selectedContentType={filters.type}
            selectedIndustry={filters.industry}
            onFilterChange={changeFilter}
            availableFilterOptions={availableFilterOptions}
          />
          <InsightsPosts
            themes={filters.theme}
            technologies={filters.technology}
            industries={filters.industry}
            contentTypes={filters.type}
            topic={content.topic?.name}
            persona={content.persona?.name}
            selectedSort={filters.ordering}
            onSortChange={changeFilter}
            onFilterChange={changeFilter}
            onFilterClearClick={clearFilter}
            pagePath={pagePath}
          />
        </ContentContainer>
        <GridStairs
          colorDown="white"
          offsets={windowWidth > breakpoints.small ? homeCollectionsStairs : headerStairsMobile}
        />

        <Reviews
          reviews={reviews}
          image={footerImage}
          windowWidth={windowWidth}
        />
        <BottomBar clutch={parsedClutch} />
      </ThemeProvider>
    </Wrapper>
  );
};

Landing.propTypes = {
  location: PropTypes.shape({
    origin: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    content: PropTypes.object,
    contentCollections: PropTypes.array,
    mock: PropTypes.object,
  }).isRequired,
};

export default Landing;
