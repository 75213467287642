import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const InsightsHead = ({ meta }) => (
  <Helmet>
    <title>{meta.title}</title>
    {meta.og_image && (
      <meta
        property="og:image"
        content={`${meta.og_image}`}
      />
    )}
    {meta.og_description && (
      <meta
        property="og:description"
        content={`${meta.og_description}`}
      />
    )}
    {meta.canonical && (
      <meta
        property="canonical"
        content={`${meta.canonical}`}
      />
    )}
    {meta.description && (
      <meta
        name="description"
        content={`${meta.description}`}
      />
    )}
  </Helmet>
);

InsightsHead.propTypes = {
  meta: PropTypes.shape({
    canonical: PropTypes.string,
    description: PropTypes.string,
    og_description: PropTypes.string,
    og_image: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default InsightsHead;
